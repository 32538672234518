<template>
    <div class="row p-0 p-xl-5 pt-xl-5">
        <div class="col-12 col-xl-8">
            <p class="main_text ps-1">
                Here’s is a list of the jobs that <span>you trusted us</span> with. Or some dummy copy to not make this page look empty.
            </p>
        </div>
        <div class="col-12 col-xl-8 pt-0">
            <div class="mb-3">
                <div class="row">
                    <div class="col-12 col-xl-4">
                        <div class="card custom_card p-3">
                            <div class="card-body">
                                <p class="card_title">
                                    Distance travelled
                                </p>
                                <h2 class="card_content">
                                    <NumberAnimation
                                        ref="number1"
                                        :from="0"
                                        :to="322"
                                        :format="formatNumberDecimal"
                                        :duration="1"
                                        easing="linear"
                                    />
                                    kms
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-xl-4">
                        <div class="card custom_card p-3">
                            <div class="card-body">
                                <p class="card_title">
                                    Tonnage collected
                                </p>
                                <h2 class="card_content">
                                    <NumberAnimation
                                        ref="number1"
                                        :from="0"
                                        :to="3.1"
                                        :format="formatNumberDecimal"
                                        :duration="1"
                                        easing="linear"
                                    /> tons
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-xl-4">
                        <div class="card custom_card p-3">
                            <div class="card-body">
                                <p class="card_title">
                                    Carbon emission
                                </p>
                                <h2 class="card_content">
                                    <NumberAnimation
                                        ref="number1"
                                        :from="0"
                                        :to="3.1"
                                        :format="formatNumberDecimal"
                                        :duration="1"
                                        easing="linear"
                                    /> tons
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mb-3">
                <div class="card custom_card p-2 p-xl-5">
                    <h3 class="card_content mb-5">
                        Landfill
                    </h3>
                    <Bar
                        id="my-chart-id"
                        :options="chartOptions"
                        :data="chartData"
                    />
                </div>
            </div>
        </div>

        <div class="col-12 col-xl-4">
            <div class="card custom_card p-2 p-xl-5 pb-0">
                <div class="row">
                    <div class="col">
                        <h3 class="card_content">Recycle</h3>
                    </div>

                    <div class="col-auto dropdown">
                        <button class="options_drop_down" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            {{ recycle_option_title }}
                            <i class="bi bi-chevron-down"></i>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a class="dropdown-item" href="#" @click="this.recycle_option_title = 'THIS WEEK'">THIS WEEK</a></li>
                            <li><a class="dropdown-item" href="#" @click="this.recycle_option_title = 'THIS MONTH'">THIS MONTH</a></li>
                            <li><a class="dropdown-item" href="#" @click="this.recycle_option_title = 'THIS YEAR'">THIS YEAR</a></li>
                        </ul>
                    </div>
                </div>
                <hr>
            </div>
            <div class="card custom_card p-2 p-xl-3 p-xxl-5">
                <div class="w-100">
                    <Doughnut ref="chartdoughnut" :data="pieData" :options="pieOptions" />
                </div>

                <div class="legend-table mt-3">
                <!-- Loop through legend items -->
                    <div v-for="(label, index) in pieData.labels" :key="index" class="legend-cell" @click="toggleChartData(this,index)">
                        <div class="legend-item">
                            <span class="legend-color" :style="{ backgroundColor: pieData.datasets[0].backgroundColor[index % pieData.datasets[0].backgroundColor.length] }"></span>
                            <span class="legend-text">{{ label }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Bar } from 'vue-chartjs';
    import { Doughnut } from 'vue-chartjs';
    import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PieController, ArcElement  } from 'chart.js';
    import NumberAnimation from "vue-number-animation";
    

    ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PieController, ArcElement )

    export default {
        name: 'BarChart',
        components: { Bar, Doughnut,NumberAnimation },
        data() {
            return {
                finalNumber: 322,
                chartData: {
                    labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
                    datasets: [{
                        data: [400000, 200000, 120000, 300000, 250000, 180000, 350000, 400000, 300000, 380000, 280000, 320000],
                        backgroundColor: '#06CCCC'
                    }]
                },
                chartOptions: {
                    responsive: true,
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                stepSize: 100000,
                                // @ts-ignore
                                callback: function(value) {
                                    return value / 1000 + 'k'; // Display values in 'k' format
                                }
                            }
                        }
                    }
                },
                pieData: {
                    labels: ['Screamin Energy Max Hit', 'Clif Shot Energy Gel', 'Vital 4U Liquid Energy', 'Clif Shot Energy Gel'],
                    datasets: [{
                        backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
                        data: [40, 20, 80, 10]
                    }]
                },
                pieOptions: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false // Hide the legend labels
                        }
                    },
                },
                recycle_option_title: 'THIS MONTH',
            }
        },
        methods: {
            toggleChartData(button,index) {
                if (this.$refs.chartdoughnut) {
                    console.log("Chart doughnut reference found", this.$refs.chartdoughnut);
                    const chart = this.$refs.chartdoughnut.chart;
                    const meta = chart.getDatasetMeta(0);
                    console.log('meta', meta, index);
                    const current = meta.data[index];
                    current.hidden = !current.hidden;
                    chart.update();
                } else {
                    console.log("Chart doughnut reference not found");
                }
            },
            formatNumber(value) {
                // Convert the value to an integer
                return Math.floor(value);
            },
            formatNumberDecimal(value) {
                // Check if the number has decimals
                if (Number.isInteger(value)) {
                    // If it's an integer, return the value without decimals
                    return value.toString();
                } else {
                    // If it has decimals, return the value with one decimal place
                    return value.toFixed(1);
                }
            }
        },
    }
</script>

<style scoped>
    .main_text{
        font-family: poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 23.68px;
        text-align: left;
    }

    .main_text span{
        color: #06CCCC;
    }

    .card_title{
        font-family: poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 23.68px;
        text-align: left;
        margin-bottom: 0;
    }

    .card_content{
        font-family: poppins;
        font-size: 24px;
        font-weight: 600;
        line-height: 31.68px;
        text-align: left;
    }

    .custom_card{
        border-radius: 12px;
        border: none;
    }

    .options_drop_down{
        background-color: #F7FAFC;
        padding: 5px 10px;
        border-radius: 10px;
        cursor: pointer;
        border: none;
    }

    .legend-table {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start; /* Align legend items to the left */
        align-items: center;
    }

    .legend-cell {
        width: 50%; /* Each legend item takes full width */
        text-align: left; /* Align legend item text to the left */
        padding: 5px;
        border-bottom: 1px solid #ccc; /* Add border between legend items */
    }

    .legend-item {
        display: flex;
        align-items: center;
    }

    .legend-color {
        width: 20px; /* Adjust as needed */
        height: 10px; /* Adjust as needed */
        margin-right: 5px; /* Adjust as needed */
    }

    .hidden {
        text-decoration: line-through;
    }

    .legend-text {
        font-size: 13px; /* Adjust as needed */
    }

    @media (min-width: 1400px) {
        .legend-text {
            font-size: 14px; /* Adjust as needed */
        }
    }
</style>