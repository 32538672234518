<template>
  <div class="body container-fluid">
    <div class="header p-3 p-xl-5 pb-3 pb-xl-3">
      <div class="row">
        <div class="col-auto">
          <img src="@/assets/website_logo.svg" class="website_logo" alt="">
        </div>
        <div class="col">

        </div>
        <div class="col-auto">
          <button class="logout_button" @click="logout">
            LOGOUT
          </button>
        </div>
      </div>

      <div class="mt-5 class_css_tab_headers d-flex flex_wrap" >
        <p class="me-5" :class="this.activeTab == 'jobs' ? 'active' : ''" @click="activeTab = 'jobs'">
          LITTA Jobs
        </p>

        <p class="me-5" :class="this.activeTab == 'your_metrics' ? 'active' : ''" @click="activeTab = 'your_metrics'">
          Your Metrics
        </p>

        <p class="me-5" :class="this.activeTab == 'compliance' ? 'active' : ''" @click="activeTab = 'compliance'">
          LITTA Compliance Documents
        </p>

        <p class="me-5" :class="this.activeTab == 'info' ? 'active' : ''" @click="activeTab = 'info'">
          Account Info
        </p>
      </div>
    </div>

    <div class="tab_body">
      <LittaJobs v-if="activeTab == 'jobs'" />
      <YourMetrics v-if="activeTab == 'your_metrics'" />
      <ComplicanceDocuments v-if="activeTab == 'compliance'" />
      <YourInfo v-if="activeTab == 'info'" />
    </div>
  </div>
</template>
  
<script>
  import YourMetrics from '@/components/dashboard-tabs/your-metrics.vue';
  import ComplicanceDocuments from '@/components/dashboard-tabs/compliance-documents.vue';
  import LittaJobs from '@/components/dashboard-tabs/litta-jobs.vue';
  import YourInfo from '@/components/dashboard-tabs/your-info.vue';
  export default {
    // Component options
    data() {
      return {
        activeTab: 'your_metrics',
      }
    },
    components: {
      YourMetrics,
      ComplicanceDocuments,
      LittaJobs,
      YourInfo,
    },
    methods: {
        logout() {
            // Remove user details from local storage
            localStorage.removeItem('commercials_user_data');

            // Redirect the user to the login page
            this.$router.push('/login');
        }
    }
  }
</script>
  
<style scoped>
  /* Component styles */
  .body{
    background-color: #F7FAFC;
    min-height: 100vh;
  }
  
  .class_css_tab_headers p{
    font-family: poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    letter-spacing: 0.02em;
    text-align: left;
    cursor: pointer;
  }

  .class_css_tab_headers p.active{
    font-family: poppins;
    font-size: 15px;
    font-weight: 800;
    line-height: 19.6px;
    letter-spacing: 0.02em;
    text-align: left;
    border-bottom: 2px solid #06CCCC;
    padding-bottom: 5px;
  }

  .flex_wrap{
    flex-wrap: wrap;
  }

  .logout_button{
      background: #FFE7E0;
      color: #D41866;
      font-size: 15px;
      font-weight: 600;
      padding: 10px 20px;
      border-radius: 50px;
      border: none;
  }
</style>