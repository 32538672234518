<template>
    <div class="p-3 p-xl-5">
        <div class="table_header p-3">
            <div class="row">
                <div class="col-auto align_contents_center pe-0">
                    <!-- <i class="bi bi-arrow-left-circle text-white"></i> -->
                </div>

                <div class="col text-center align_contents_center">
                    <p>
                        Commercial Jobs Today(17)
                    </p>
                </div>
                <div class="col-12 col-sm-auto d-flex align_contents_center mx-auto mt-3 mt-sm-0 justify-content-center">
                    <span>Group By: </span>
                    <div class="dropdown">
                        <button class="btn btn-white dropdown-toggle ms-3" type="button" id="commercialgroupby" data-bs-toggle="dropdown" aria-expanded="false">
                            Assigned To
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="commercialgroupby">
                            <li><a class="dropdown-item" href="#">Assigned To</a></li>
                            <li><a class="dropdown-item" href="#">Assigned To</a></li>
                            <li><a class="dropdown-item" href="#">Assigned To</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">References</th>
                        <th scope="col">Status</th>
                        <th scope="col">Time Slot</th>
                        <th scope="col">Postcode</th>
                        <th scope="col">Service</th>
                        <th scope="col">Commercial Account</th>
                        <th scope="col">Arrival Time</th>
                        <th scope="col">Completion Time</th>
                        <th scope="col">Time at Site</th>
                        <th scope="col">RM</th>
                        <th scope="col">WTN Created</th>
                        <th scope="col">WTN Sent</th>
                        <th scope="col">JC Created</th>
                        <th scope="col">JC Sent</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(item,index) in this.collection" :key="index">
                        <tr>
                            <td colspan="14">
                                <div class="company_name">
                                    {{ item.name }}
                                </div>
                            </td>
                        </tr>
                        <tr v-for="(item2,index2) in item.records" :key="index2">
                            <td>{{ item2.reference }}</td>
                            <td>{{ item2.status }}</td>
                            <td>{{ item2.time }}</td>
                            <td>{{ item2.postcode }}</td>
                            <td>{{ item2.service }}</td>
                            <td>{{ item2.commercial }}</td>
                            <td>{{ item2.arrival }}</td>
                            <td>{{ item2.completion }}</td>
                            <td>{{ item2.time_site }}</td>
                            <td>{{ item2.rm }}</td>
                            <td v-if="item2.wtn_created == true">
                                <i class="bi bi-check text-success"></i>
                            </td>
                            <td v-else>
                                ?
                            </td>
                            <td v-if="item2.wtn_sent == true">
                                <i class="bi bi-check text-success"></i>
                            </td>
                            <td v-else>
                                ?
                            </td>
                            <td v-if="item2.jc_created == true">
                                <i class="bi bi-check text-success"></i>
                            </td>
                            <td v-else>
                                ?
                            </td>
                            <td v-if="item2.jc_sent == true">
                                <i class="bi bi-check text-success"></i>
                            </td>
                            <td v-else>
                                ?
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return {
                collection: [],
            }
        },
        mounted() {
            let temp_object = {
                id: 123,
                name: 'BIN MAN LIMITED',
                records: [
                    {
                        reference: 'LTC87545',
                        status: 'success',
                        time: '10:30 - 11:30',
                        postcode: 'EC2M 4RB',
                        service: 'Maxi',
                        commercial: 'Maris Interrior',
                        arrival: '09:51:37',
                        completion: '10:24:07',
                        time_site: '32 minutes',
                        rm: 'Chris Yiannopoulous',
                        wtn_created: '?',
                        wtn_sent: '?',
                        jc_created: '?',
                        jc_sent: '?',
                    },
                    {
                        reference: 'LTC87545',
                        status: 'success',
                        time: '10:30 - 11:30',
                        postcode: 'EC2M 4RB',
                        service: 'Maxi',
                        commercial: 'Maris Interrior',
                        arrival: '09:51:37',
                        completion: '10:24:07',
                        time_site: '32 minutes',
                        rm: 'Chris Yiannopoulous',
                        wtn_created: '?',
                        wtn_sent: '?',
                        jc_created: true,
                        jc_sent: true,
                    },
                ],
            };
            this.collection = [];
    
            // Push the temp_object into the collection array five times
            for (let i = 0; i < 5; i++) {
                this.collection.push(temp_object);
            }
        },
    }
</script>

<style scoped>
    .table_header{
        background: #1E7F78;
        color: #fff;
    }

    .table_header p{
        font-size: 20px;
        font-family: poppins;
        font-weight: 800;
        margin-bottom: 0;
    }

    .table_header span{
        align-items: center;
    }

    .align_contents_center{
        align-content: center;
        align-items: center;
    }

    .btn-white{
        background-color: #fff;
    }

    .company_name{
        background-color: #E9EEF9;
        color: #000;
        padding: 5px 20px;
        width: fit-content;
        border-radius: 10PX;
    }

    i.text-success{
        font-weight: 900;
        font-size: 24px;
    }

    @media(max-width:764px){
        .table_header p{
            font-size: 16px;
        }
    }
</style>