<template>
    <!-- <h2 class="card_title p-3 p-xl-5 pb-0 pb-xl-0 pt-0 pt-xl-0">
        Account Details
    </h2> -->
    <div class="row p-3 p-xl-5 pt-2 pt-xl-2 d-flex" v-if="endpointData.length>0">
        <div class="col-12 col-xl-8 mx-auto">
            <div class="card custom_card p-3 w-100">
                <div class="row mb-3" v-for="(data,index) in endpointData" :key="index">
                    <div class="col-6">
                        {{ data.fieldName }}
                    </div>

                    <div class="col-6 text_right">
                        {{ data.value }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    export default{
        data() {
            return{
                endpointData: [],
            }
        },
        mounted() {
            axios.get('https://portal-litta-api.web.app/commercial/users/GdiL3iqKBFQMvytKsx1gFe9Y1h/info').then(response=>{
                this.endpointData = response.data.data;
                console.log('endpoint_data', this.endpointData);
            }).catch(error=>{
                console.log('error occured in server', error);
            });
        },
    }
</script>

<style scoped>
    .main_text{
        font-family: poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 23.68px;
        text-align: left;
    }

    .main_text span{
        color: #06CCCC;
    }

    .card_title{
        font-family: poppins;
        font-size: 22px;
        font-weight: 800;
        line-height: 23.68px;
        text-align: left;
        margin-bottom: 0;
    }

    .card_content{
        font-family: poppins;
        font-size: 24px;
        font-weight: 600;
        line-height: 31.68px;
        text-align: left;
    }

    .custom_card{
        border-radius: 12px;
        border: none;
    }

    .options_drop_down{
        background-color: #F7FAFC;
        padding: 5px 10px;
        border-radius: 10px;
        cursor: pointer;
        border: none;
    }

    .text_right{
        font-size: 17px;
        font-weight: 600;
    }

    .text_right a{
        font-size: 17px;
        font-weight: 600;
        color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
        cursor: pointer;
        text-decoration: underline;
    }
</style>