import { createRouter, createWebHistory } from 'vue-router';
import Home from './pages/home-page.vue';
import Login from './pages/login-page.vue';
import Signup from './pages/signup-page.vue';
import Forgot from '@/pages/forgot-password.vue';

const routes = [
    { path: '/', component: Home },
    { path: '/login', component:  Login},
    { path: '/signup', component: Signup },
    { path: '/forgot-password', component: Forgot }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach(async (to, from, next) => {
    const isLoggedIn = localStorage.getItem('commercials_user_data');
    if (to.path === '/login' || to.path === '/signup' || to.path === '/forgot-password') {
        if (isLoggedIn) {
            next('/');
        } else {
            next();
        }
    } else {
        if (!isLoggedIn) {
            next('/login');
        } else {
            next();
        }
    }
});

export default router;