<template>
    <div class="body">
        <CommonHeader />
        <div class="container">
            <div class="row">
                <div class="col-12 col-xl-5 justify-content-center mx-auto">
                    <div class="login_container ps-2 pe-2 ps-lg-3 pe-lg-3 ps-xl-5 pe-xl-5">
                        <p class="login_heading mt-3">
                            Sign up to LITTA <span class="tm">TM</span>
                        </p>

                        <div class="mt-4 form-group">
                            <div class="mb-3" :class="{ 'has-error': emailError }">
                                <div class="input_wrapper">
                                    <input type="text" class="form-control auth_input" placeholder="Email" v-model="email">
                                    <i class="bi bi-person input_icon"></i>
                                </div>
                                <p class="error-message text-left ps-3" v-if="emailError">{{ emailError }}</p>
                            </div>

                            <div class="mb-3" :class="{ 'has-error': passwordError }">
                                <div class="input_wrapper">
                                    <input class="form-control auth_input password_input" placeholder="Password" :type="showPassword ? 'text' : 'password'" v-model="password">
                                    <i class="bi bi-lock input_icon"></i>
                                    <i class="bi bi-eye eye_icon" @click="togglePasswordVisibility"></i>
                                </div>
                                <p class="error-message text-left ps-3" v-if="passwordError">{{ passwordError }}</p>
                            </div>

                            <div class="input_wrapper mb-3">
                                <input class="form-control auth_input password_input" placeholder="Confirm Password" :type="showConfirmPassword ? 'text' : 'password'" v-model="confirm_password">
                                <i class="bi bi-lock input_icon"></i>
                                <i class="bi bi-eye eye_icon" @click="toggleConfirmPasswordVisibility"></i>
                            </div>

                            <button class="login_button mb-5" @click="performSignup()">
                                Create My Account
                            </button>

                            <div class="mb-3">
                                <p class="dont_account">
                                    Already have a LITTA<span>TM</span> account?
                                </p>
                            </div>

                            <div class="mb-3">
                                <router-link class="navbar-brand mb-3" to="/login">
                                    <button class="signup_button">
                                        Log In
                                    </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CommonHeader from '@/components/auth-header/common-header.vue';
    import Swal from 'sweetalert2/dist/sweetalert2.js';
    import { auth } from '@/firebase';
    import { createUserWithEmailAndPassword } from 'firebase/auth';
    export default {
        data() {
            return {
                email: '',
                password: '',
                confirm_password: '',
                showPassword: false,
                showConfirmPassword: false,
                emailError: '',
                passwordError: '',
            }
        },
        components: {
            CommonHeader,
        },
        methods: {
            togglePasswordVisibility() {
                this.showPassword = !this.showPassword; // Toggle password visibility
            },
            toggleConfirmPasswordVisibility() {
                this.showConfirmPassword = !this.showConfirmPassword; // Toggle password visibility
            },
            async performSignup() {
                this.emailError = '';
                this.passwordError = '';

                if (!this.email) {
                    this.emailError = 'Please fill email field';
                    return;
                } else if (!this.isValidEmail(this.email)) {
                    this.emailError = 'Please enter a valid email address';
                    return;
                }

                if (!this.password) {
                    this.passwordError = 'Please fill password field';
                    return;
                } else if (this.password !== this.confirm_password) {
                    this.passwordError = 'Passwords do not match';
                    return;
                }

                try {
                    const userCredential = await createUserWithEmailAndPassword(auth, this.email, this.password);
                    const user = userCredential.user;
                    console.log('Logged in user:', user);
                    localStorage.setItem('commercials_user_data', JSON.stringify(user));
                    this.$router.push('/');
                    // You can perform further actions here after successful signup
                } catch (error) {
                    Swal.fire({
                        title: 'Error!',
                        text: error.message,
                        icon: 'error'
                    });
                }
            },
            isValidEmail(email) {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return emailRegex.test(email);
            }
        },
        mounted() {

        },
    }
</script>

<style scoped>
    .body{
        background-color: #F7FAFC;
        min-height: 100vh;
    }

    .login_container{
        border: 1px solid rgb(213 224 213);
        text-align: center;
        border-radius: 10px;
    }

    .login_heading{
        font-size: 24px;
        font-weight: 700;
        vertical-align: top;
    }

    .login_heading .tm{
        font-size: 10px;
        vertical-align: top;
        position: relative;
        top: 5px;
    }

    .auth_input{
        padding-left: 50px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 50px;
    }

    .input_wrapper {
        position: relative;
    }

    .input_wrapper input {
        padding-left: 30px; /* Space for the icon */
    }

    .input_icon {
        position: absolute;
        left: 10px; /* Adjust as needed */
        top: 50%;
        transform: translateY(-50%);
    }

    .eye_icon {
        position: absolute;
        right: 20px; /* Adjust as needed */
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }

    .password_input{
        padding-right: 40px;
    }

    .forgot_password{
        color: #000;
        font-size: 18px;
        text-decoration: underline;
        text-align: right;
        cursor: pointer;
    }

    .login_button{
        background-color: rgb(6 204 204);
        color: #fff;
        width: 100%;
        border-radius: 15px;
        padding: 10px 10px;
        border: none;
    }

    .google_login{
        background: rgb(66 133 244);
        width: 100%;
        padding: 5px 5px;
        color: #fff;
        border-radius: 50px;
        border: none;
    }

    .google_login img{
        float: left;
    }

    .apple_login{
        background: #fff;
        width: 100%;
        padding: 5px 5px;
        color: #000;
        border-radius: 50px;
        border: 1px solid #000;
    }

    .dont_account{
        color: #000;
        font-size: 19px;
    }

    .dont_account span{
        font-size: 10px;
        vertical-align: top;
        position: relative;
        top: 5px;
    }

    .signup_button{
        color: rgb(6 204 204);
        border: 2px solid rgb(6 204 204);
        width: 100%;
        background: transparent;
        padding: 5px 10px;
        border-radius: 15px;
    }

    .has-error input {
        border-color: red;
    }

    .error-message {
        color: red;
        font-size: 14px;
        margin-top: 5px;
        text-align: left;
    }

</style>
